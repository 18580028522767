export default {
  article_17_title: "Add-ons – mobile application",
  article_18_title: "Add-ons – mobile application – personalization",
  article_19_title: "Add-ons – Stripe payments",
  wb_hide_booking_employees: "Hide employees in the booking window",
  wb_og_image_url: "Open Graph URL image (og:image)",
  wb_favicon_url: "Favicon URL",
  wb_elevation: "Shadow",
  wb_show_sign_in_button: 'Show "Sign in" button',
  wb_show_sign_up_button: 'Show "Create an account" button',
  wb_show_active_button: "Show the active button",
  wb_show_company_name: "Show the company name",
  wb_button_size_type_x_small_title: "XS",
  wb_button_size_type_small_title: "S",
  wb_button_size_type_medium_title: "M",
  wb_button_size_type_large_title: "L",
  wb_button_size_type_x_large_title: "XL",
  wb_button_size_title: "Button size",
  auth: "Authentication",
  booking_created_no_notifications_label:
    "Don't inform about the creation of the visit (no notifications)",
  booking_updated_no_notifications_label:
    "Don't send a notification after changing/deleting the visit",
  booking_deleted_no_notifications_label:
    "Don't send a notification after deleting the event",
  booking_no_notifications_tooltip:
    "The customer and the employee will not receive e-mail, SMS and Push notifications after creating / changing the visit. They will receive reminder notifications before the visit (if you have them turned on).",
  recurring_booking: "This event recurs",
  repeat_every: "Repeat every",
  day: "Day",
  week: "Week",
  month: "Month",
  year: "Year",
  day_plural_1: "Day",
  day_plural_2: "Days",
  day_plural_3: "Days",
  day_plural_4: "Days",
  day_plural_5: "Days",
  week_plural_1: "Week",
  week_plural_2: "Weeks",
  week_plural_3: "Weeks",
  week_plural_4: "Weeks",
  week_plural_5: "Weeks",
  month_plural_1: "Month",
  month_plural_2: "Months",
  month_plural_3: "Months",
  month_plural_4: "Months",
  month_plural_5: "Months",
  year_plural_1: "Year",
  year_plural_2: "Years",
  year_plural_3: "Years",
  year_plural_4: "Years",
  year_plural_5: "Years",
  booking_plural_1: "Booking",
  booking_plural_2: "Bookings",
  booking_plural_3: "Bookings",
  booking_plural_4: "Bookings",
  booking_plural_5: "Bookings",
  ends_after_occurrences_1: "Ends after",
  ends_after_occurrences_2: "occurrences",
  ends_at_date: "Ends on",
  delete_recurring_booking_title: "Delete recurring event",
  delete_recurring_booking_question:
    "This event is a recurring event. What would you like to delete?",
  delete_recurring_booking_option_1: "Only this one event",
  delete_recurring_booking_option_2: "This event and all following ones",
  delete_recurring_booking_option_3:
    "All events, including those that have already taken place",
  all: "All",
  payment_2: "Payment",
  recurring_booking_ends_info:
    "The maximum number of recurring events has been exceeded. Change the parameters and try again.",
  payment_method_stripe: "Stripe",
  payment_method_cash: "Cash",
  payment_method_transfer: "Transfer",
  payment_method_card: "Card",
  payment_method_subscription: "Subscription",
  payment_method_other: "Other",
  booking_payment_transaction_create_title: "Pay for the booking",
  booking_payment_transaction_remove_title: "Delete the payment for the visit",
  payment_transaction_transaction_id: "Transaction number / comment",
  invalid_time: "Invalid time",
  add_new_day: "Add a new day",
  max_people_title:
    "The maximum number of customers at the same time with one employee",
  wb_date_label: "Date label",
  wb_start_calendar_time: "Show from the hour",
  wb_end_calendar_time: "Show up to the hour",
  wb_interval_minutes: "Minimum duration of the event in the calendar",
  minutes: "min",
  wb_booking_note: "Additional information",
  wb_require_phone_number: "Require a phone number in the form",
  wb_hide_signup: "Hide sign up option",
  booking_form_custom_fields: "Booking form fields",
  booking_form_custom_fields_equal_note: "Expected value",
  clear_filters: "Reset filters",
  wb_hide_login: "Hide login option",
  wb_hide_header: "Hide the header",
  wb_hide_footer: "Hide the footer",
  wb_add_element: "Add a form field",
  wb_element_title: "Form field",
  wb_dynamic_form_element_type_1: "Text field",
  wb_dynamic_form_element_type_2: "Text area",
  wb_dynamic_form_element_type_3: "Checkbox",
  wb_dynamic_form_element_type_4: "Radio button",
  wb_dynamic_form_element_type_5: "Email",
  wb_dynamic_form_element_type_6: "Phone",
  wb_dynamic_form_element_type_7: "Address",
  wb_dynamic_form_should_require: "Mandatory field",
  wb_dynamic_form_value_equals: "Check against the value",
  wb_dummy_text_summary_headline: "Thank you for booking an appointment",
  wb_summary_headline: "Summary title",
  wb_summary_text: "Summary text",
  wb_view_outlined: "Outlined view",
  wb_view_flat: "Flat view",
  wb_view_rounded: "Rounded view",
  wb_select_date_hour: "Text above the calendar",
  wb_book_now_label: "Booking button",
  wb_html_code: "HTML Code",
  wb_zoom: "Zoom",
  wb_longitude: "Longitude",
  wb_latitude: "Latitude",
  wb_map_maker_hide: "Hide the pin on the map",
  service_location: "Location",
  service_locations: "Locations",
  service_location_info:
    "Location defines the way of meeting or connecting with clients. This information will be visible on the reservation confirmation page and will also be included in the emails sent. The location information will be added to the respective event if you synchronize Calendesk with external calendars. You can add multiple locations (online or offline), in which case clients will be asked to make a choice.",
  service_location_1: "Address",
  service_location_2: "Phone",
  service_location_3: "Google Meet",
  service_location_4: "Other",
  service_location_5: "Zoom",
  service_location_6: "Microsoft Teams",
  service_location_7: "Skype",
  service_location_8: "WhatsApp",
  select_service_location_label: "Select location",
  service_location_1_data_label: "Enter the address of the location",
  service_location_2_data_label: "Enter phone number",
  service_location_3_data_label: "Enter additional information",
  wb_image_margin_bottom: "The space in pixels below the image",
  wb_text_margin_bottom: "The space in pixels below the text",
  plugins_fakturownia: "InvoiceOcean",
  fakturownia_description:
    "InvoiceOcean is a simple online invoicing software. With InvoiceOcean and Calendesk, you can accept customer payments and create VAT invoices. So say goodbye to manual work.",
  fakturownia_description_2:
    "Add below your API token and account URL address. You will find both of these values after logging in to your InvoiceOcean account. Find the API token in Settings -> Account settings -> Integration -> API authorization code. Find your account URL address in Settings -> Account Settings.",
  fakturownia_enabled_title: "Enable the InvoiceOcean add-on",
  fakturownia_send_invoice_title:
    "Automatically email invoices to customers upon receipt of payment.",
  fakturownia_api_token_title: "API Token",
  fakturownia_gtu_codes_title: "GTU codes",
  fakturownia_account_url_title: "URL address of the account",
  stripe_id_label: "Stripe ID",
  fakturownia_id_label: "InvoiceOcean ID",
  private_notes_for_customer_label: "Private notes about the client",
  tax_label: "Tax",
  wants_invoice_title: "Invoice",
  fakturownia_validation_error:
    "Calendesk cannot connect to the InvoiceOcean's system. The given data is incorrect.",
  feature_not_available_title:
    "Unfortunately, this feature is not available on your plan. Change your plan to unlock all Calendesk features.",
  only_subscriptions_label:
    "Allow booking this service only for the customers with a subscription.",
  subscriptions: "Subscriptions",
  subscription: "Subscription",
  subscriptions_list: "List of subscriptions",
  subscription_payments: "Payment",
  each: "Each",
  subscription_name: "The name of the subscription in Calendesk",
  subscription_name_example: "e.g. Monthly consultations",
  invoice_info_1:
    'Select the option of issuing invoices if you want the system to automatically issue an invoice for the payment transaction (Requires correct configuration of an add-on for issuing VAT invoices, e.g., InvoiceOcean, which can be found in the "Add-ons").',
  wants_invoice_select_title: "Issue a VAT invoice after receiving the payment",
  tax_amount_title:
    "The percentage of VAT tax which is already included in the price",
  product_in_stripe: "Product in Stripe",
  product_price_in_stripe: "The price of the product in Stripe",
  product_name: "The name of the product in Stripe",
  add_new_stripe_product_title: "Add a new product in Stripe",
  add_new_stripe_product_price_title: "Add a new product price in Stripe",
  subscription_recurring_title: "Recurring payment",
  service_limits_label:
    "Limit the number of possible bookings under this subscription.",
  service_limits_count_label: "Maximum number of bookings",
  service_limits_reset_time:
    "Reset the number of available bookings periodically",
  available_services_in_subscription: "Available services in the subscription",
  subscription_settings: "Subscription settings",
  payment_settings: "Payment settings",
  subscriptions_settings_information:
    "Decide what type of subscription you want to offer your customers. You can choose services for which the subscription will apply. Name the subscription in a way that will allow the customer to understand its purpose. You can also decide whether the subscription should have limits on the bookings of selected services (e.g. 10 bookings). Limits can be reset at certain times (e. g. you can set 10 bookings per month for a customer).",
  subscription_payment_settings_information:
    "Decide how your customers will pay for the subscription. For this, you need a product in Stripe and a price determination, which will be created on your Stripe account (click the plus icon to create). Payment can be one-time or spread over time (e.g., $100 per month). Thanks to various combinations of subscription and payment settings, you can create very complex subscriptions (e.g., 10 bookings for a service per month with a one-year payment of $999).",
  bookings_number: "Number of bookings",
  recurrent_bookings_reset: "Reset every",
  recurrent_payment_each: "Payment periodically every",
  recurrent_each: "Repeat every",
  subscription_success_url: "Redirect to URL after correct payment",
  subscription_redirect_title: "Optional redirects",
  subscription_success_url_example: "E.g. https://your-page.com/success",
  subscription_cancel_url: "Redirect to URL after incorrect payment",
  subscription_cancel_url_example: "E.g. https://your-page.com/error",
  subscription_redirect_question:
    "Where do you want to redirect users after correct or incorrect subscription payments?",
  booking_payment_urls_question:
    "Where do you want to redirect users after correct or incorrect payments for the bookings (this setting only applies to payments via your website in the Calendesk system)?",
  select_subscription: "Choose subscription",
  subscription_active: "Subscription is active",
  remove_subscription_not_possible_error_notification:
    "Active subscriptions cannot be deleted.",
  user_subscriptions: "Subscriptions",
  active: "Active",
  inactive: "Inactive",
  initiated: "Unpaid",
  canceled: "Canceled",
  no_user_subscriptions_info:
    "You don't have any subscriptions assigned to your customers yet. Click below to manually assign a subscription to your customer or ask your customers to purchase a subscription directly from your website.",
  go_to_subscriptions: "Go to subscriptions",
  stripe_subscription_id: "Stripe ID",
  provider_tag: "Method",
  card: "Card",
  cash: "Cash",
  transfer: "Transfer",
  cancel_subscription_success:
    "The selected subscriptions have been correctly cancelled.\t",
  cancel_subscription_fail: "Failed to cancel the selected subscriptions.",
  cancel_succeeded: "Successfully cancelled",
  cancel_failed: "An error occurred while cancelling",
  ends_at: "Ends at",
  ends_at_default: "Valid until the end of the billing period",
  number_of_user_subscriptions: "Number of subscriptions sold",
  number_of_simple_store_products: "Number of products sold",
  number_of_active_user_subscriptions: "Active subscriptions",
  number_of_simple_store_product_transactions: "All products sold",
  number_of_simple_store_product_transactions_today: "Products sold today",
  number_of_simple_store_product_transactions_this_week:
    "Products sold this week",
  number_of_bookings_by_amount_title: "Bookings revenue",
  number_of_user_subscriptions_by_amount_title: "Subscriptions revenue",
  number_of_simple_store_product_by_amount_title: "Products revenue",
  disable_configuration: "Hide configuration progress",
  enable_configuration: "Show configuration progress",
  quick_configuration: "Quick setup",
  stepper_1_title: "Your calendar is now online, see what it looks like",
  stepper_1_title_a: "Create your online calendar",
  stepper_1_description:
    "Click below to see our proposed calendar for your clients. Thanks to it, you can accept reservations for your services completely online. What you see is just the beginning of what Calendesk can do. Set colors, add photos, descriptions and bookmarks in an intuitive website builder.",
  stepper_1_description_a:
    "Click below to create a calendar or website for your customers. You will be able to accept bookings for your services completely online. Set colors, add photos, descriptions, and bookmarks in an intuitive website builder.",
  open_website_button: "Open the website",
  edit_website_button: "Edit the website",
  calendar_url_link: "Your calendar URL",
  stepper_2_title: "Who will accept bookings?",
  stepper_2_description:
    "You can have multiple schedules, whether you work alone or hire employees. The employee's account is a separate calendar with its schedule. We have already created the first employee for you. This is your account with your schedule. Customize it as you need.",
  view_employees_account_button: "View employee accounts",
  stepper_3_title: "What services do you offer to your customers?",
  stepper_3_description:
    "List the services you want to present to your clients. Each of the services may have a different configuration. You can set different prices, service durations and assign services to various employees. We have created your first service for you. Next, you need to adjust it to yourself.",
  view_services_button: "View services",
  stepper_4_title: "Will you accept online payments?",
  stepper_4_description:
    "Use the convenient online payment system and let your customers pay for your services. Payments in Calendesk, in combination with the Stripe system, are not only prepayments from customers. It is an advanced payment operator that, in combination with Calendesk, allows much more. The setup takes a few minutes and is necessary to process payments in Calendesk.",
  configure_payments_button: "Configure payments",
  stepper_5_title: "Additional help",
  stepper_5_description:
    "Do you need more information? Having trouble with your system configuration? Please make an appointment for a free consultation with our consultant or use the Help Center, which includes guides in the form of articles and videos. We are also available to you in the chat. Just click the chat icon in the bottom right corner.",
  meet_us: "Free consultation",
  help_center: "Help Center",
  finish: "Done",
  go_to_tab: "Go to the tab",
  with: "with",
  wb_page_only_with_auth: "The subpage will be visible only to logged-in users",
  wb_page_only_with_auth_short: "Only to logged-in users",
  wb_menu_button_only_with_auth:
    "The menu button will be visible only to logged-in users",
  wb_remind_password_label: "Remind password link",
  wb_show_remind_password_link: "Enable the ability to remind the password",
  wb_redirect_after_login: "Redirect after logging in to",
  wb_redirect_after_signup: "Redirect after signing up to",
  wb_billing_data_checkbox_question: "Billing details",
  wb_gdpr_fields: "GDPR fields",
  wb_dummy_gdpr_field: "You accept our Terms of Service and Privacy Policy",
  wb_add_rgdpr_field_element: "Add GDPR field",
  wb_gdpr_field_name: "The internal name of the field",
  wb_dummy_gdpr_field_name: "GDPR field",
  wb_enable_discounts: "Enable the possibility of entering discount codes",
  subscriptions_stripe_account_missing_description:
    "You have to finish the setup of a Stripe payment account to use subscriptions.",
  optional_field: "Optional field",
  select_all: "Select all",
  unselect_all: "Unselect all",
  wb_show_billing_data: "Allow filling the invoice details",
  edit_email: "Edit email",
  edit_sms: "Edit SMS",
  edit_push: "Edit Push",
  booking_control_url_example:
    "https://your-website.calendesk.net/bookings/5de239ca-c1e1-4040-743s-195ef52271aa",
  custom_fields_example:
    '<h3 class="mb0">Additional information:</h3><p>I look forward to our meeting!</p>',
  usage: "Usage",
  insufficient_permissions_error: "You do not have sufficient permissions.",
  additional_auth_required: "Your bank requires additional authorization",
  general_payment_error:
    "There was a problem with your payment method. Perhaps your card does not support subscriptions. Please contact your bank or choose a different payment method. If you need help, feel free to chat with us.",
  invoice_data_update_fail:
    "Your billing address has been rejected. Ensure that the VAT number entered and the rest of the data are correct, and please try again.",
  wb_section_hidden_info: 'In the "General" tab, you can restore the section.',
  add_to_your_website: "Install on your website",
  add_to_your_website_description:
    "Select the element that you want to add to your website.",
  add_to_your_website_description_widget_1:
    "Place this code in your HTML on your website where you want your calendar to appear.",
  add_to_your_website_description_widget_2:
    "Place this code in your HTML on the website you want your floating button to appear. Then, when a visitor clicks the button, your Calendesk booking page will load as a popup.",
  add_to_your_website_description_widget_3:
    "Place this code in your HTML where you want your Calendesk booking link to appear. Then, when a visitor clicks the link, your Calendesk booking page will load as a popup.",
  widget_1_title: "Inline Embed",
  widget_1_description:
    "Your calendar as a permanent element of the website. Your customers will see the calendar right away.",
  widget_2_title: "Popup Widget",
  widget_2_description:
    "A floating button at the bottom of your page that launches your booking site as a popup.",
  widget_3_title: "Popup Link",
  widget_3_description:
    "A text link on your website in places you want to launch your booking site as a popup.",
  widget_dummy_1: "Make an appointment",
  button_text: "Button text",
  link_text: "Link text",
  text_color: "Text color",
  background_color: "Background color",
  copy_code_info: "Copy the code and paste it on your website",
  field_only_for_services: "Show the form field only for selected services",
  user_preferences: "Settings",
  service_days_title:
    "Additionally, limit the possibility of booking to selected days and hours (reservations will be limited by the employee's schedule and an additional limitation of this setting)",
  service_days_description:
    "You can limit the booking of this service to only specific days and hours of the week. For example, if your service is only on Fridays at 19, the customer will see only one hour of availability despite the employee's schedule set for the whole day. However, this option is not required, and if you do not set it, your calendar for customers will display the available booking slots according to the employees' schedules.",
  disconnect_stripe_account: "Disconnect your Stripe account",
  disconnect_stripe_account_success: "Account disconnected correctly.",
  connect_current_stripe_account_question: "I already have a Stripe account",
  connect_current_stripe_account_number: "Your current Stripe ID",
  connect_current_stripe_account_number_error:
    'Please enter your Stripe ID, or if you don\'t have a Stripe account yet, please uncheck "I already have a Stripe account".',
  stripe_connect_account_failed:
    "There was an error connecting your account. If the error persists, don't hesitate to get in touch using chat or email.",
  wb_text_hide: "Hide text",
  wb_radio_add_option: "Add an option",
  wb_form_element_type_title: "Select the type of field in the form",
  wb_email_subject: "Email subject",
  wb_email_form_success_url: "Redirect to URL after submitting the form",
  wb_email_sent: "The content of the message after sending the form",
  form: "Form",
  wb_section_text: "Text in the section",
  wb_flexible_form_info:
    "After sending the form, you will receive a message to your email address defined in the Calendesk panel (Profile tab -> Your company). Below you can set the message's subject and select fields that should be added to the form.",
  wb_section_info: "Configure the selected section using the settings below.",
  wb_general_tab_info:
    "Configure the general website settings below, such as language, Google Tag ID, browser icon, description for robots, and an image displayed when sharing the link, e.g., in social media. You can use images uploaded to your gallery. Copy the image URL from its preview (you will find the image URL at the bottom of the image).",
  wb_pages_tab_info:
    'Select a subpage below by clicking on its name. You will be able to edit its sections. If you want to change its name or URL address, click the icon next to the name of the subpage and select "Edit".',
  buttons: "Buttons",
  wb_form_label_info: "Label (information in the field, e.g., Name)",
  wb_form_description_info:
    "Description (information above the field, e.g., Enter your name)",
  click_to_edit: "Tap to edit",
  unavailable_booking_slots: "Blackout Dates",
  all_day: "I want to set full days",
  all_day_tooltip:
    "You will see events created as full days in the calendar's top bar and the monthly view.",
  block_calendar: "Add a blackout date",
  integrations: "Integrations",
  google_calendar_and_meet: "Google Calendar + Google Meet",
  zoom: "Zoom",
  zoom_connect_info:
    "By linking your Calendesk and Zoom accounts together, you can easily and automatically create videoconferences with your customers. You can set your service location in Calendesk to Zoom to automatically create a meeting link for customers when they book your service. ",
  zoom_connect_info_short:
    "Use simplified video conferencing and messaging across any device.",
  zoom_connect_button_title: "Connect Zoom",
  zoom_disconnect_info:
    'The connection with Zoom is active. Click "Disconnect" to disconnect.',
  zoom_disconnect_button_title: "Disconnect Zoom",
  zoom_auth_success_title: "Zoom authorization successful",
  zoom_auth_success_description:
    "Thank you, you have successfully authorized the Zoom account. Go to your services to select the meeting location to Zoom.",
  zoom_auth_fail_description:
    "There was a problem authorizing Zoom. Try again.",
  zoom_disconnect_dialog_info:
    "Are you sure you want to remove the Zoom integration?",
  zoom_disconnect_success_message:
    "The integration with Zoom has been correctly removed.",
  settings_alphanumeric_sms_sender_title: "Personalize the SMS sender name",
  calendesk_name_example: "e.g., Calendesk",
  settings_alphanumeric_sms_sender_input_title: "SMS Sender",
  settings_alphanumeric_sms_sender_validation_error:
    "Invalid sender format. Only letters, spaces, and numbers are accepted. At least one letter is required. The name can contain up to 11 characters.",
  settings_alphanumeric_sms_sender_description:
    "SMS sender name in all text messages to your customers. Replace boring and random phone numbers with your custom name. Let your customers know right away it's a message from you. The name must contain at least one letter. Accepted values are letters, numbers, and spaces. Maximum 11 characters.",
  enable_package: "Enable package",
  package_not_available_title:
    "You need an additional package to use this feature. Please go to the store to enable the package.",
  subscription_update_information:
    "If you change your subscription plan or activate add-on packages during the billing period, the payment operator will recalculate the amounts and charge you only the required difference.",
  wb_only_services_with_subscriptions:
    "Only show services for which the logged-in customer has active subscriptions",
  wb_use_booking_cart: "Use the booking cart",
  wb_selected_services: "Title in the booking cart",
  wb_count_places_in_dates: "Show all free number of spots (group services)",
  number_of_sms_messages: "Number of SMS messages",
  api: "API",
  api_description:
    "Endless integration possibilities with Calendesk thanks to the RESTful API connection. Use the Calendesk API for custom solutions.",
  generate_api_key_button: "Generate API key",
  delete_api_key: "Delete API key",
  api_key: "API key",
  open_api_documentation: "API documentation",
  tenant_name: "Tenant name",
  service_limits: "Additional limits for this service",
  service_availability_title: "The service availability for customers",
  choose_availability_schedule: "Select availability schedule",
  add_new_availability_schedule: "Add new availability schedule",
  update_availability_schedule: "Edit availability schedule",
  add_schedules_with_plus:
    'No availability schedules. Add an availability schedule by clicking "+" on the right.',
  schedule_name: "Schedule name",
  display_schedule: "Edit schedule",
  delete_schedule: "Delete schedule",
  weekdays: "Weekdays",
  schedule_dates: "Date overrides",
  schedule_dates_info:
    "Add dates when the availability changes from the weekly hours",
  add_new_date: "Add date",
  hours_availability_info: "Available hours",
  select_availability_dates_info: "1. Select availability days",
  select_availability_hours_info: "2. Select availability hours",
  fill_availability_schedules:
    "Select days or dates that will be available in the schedule",
  plan_unavailable_error:
    "Your current Calendesk settings do not allow you to choose this plan. If your trial period has expired, please get in touch with us so we can extend it.",
  value: "Value",
  no_data: "No data",
  created_by: "Created by",
  updated_by: "The last change made by",
  updated_at: "Updated at",
  my_booking: "My bookings",
  payment_user_is_required:
    "No customer is assigned. An active customer is required to make a payment.",
  promo_number_of_months: "The number of months the price is valid:",
  wb_newsletter_success_url: "URL newsletter - confirmation redirect",
  wb_account_activation_success_url:
    "URL Account Activation - confirmation redirect",
  wb_show_name_surname: "Show name and surname in the form",
  wb_show_phone_number: "Show phone number in the form",
  wb_hide_time_zone: "Hide time zone selection",
  wb_add_code_to_website_head:
    'Copy the code from below and paste it to your website code between "<head>" and "</head>" tags.',
  wb_add_code_to_website_frame:
    "Copy the code from below and paste it to your website code in the place where you want to display your calendar.",
  wb_height_desktop: "The height in pixels on computers",
  wb_height_mobile: "The height in pixels on smartphones",
  google_calendar_add_title:
    "Send bookings and blackout dates to Google Calendar",
  google_calendar_add_info:
    "Select Google Calendar to which we should send new bookings and blackout dates from Calendesk.",
  select_calendar: "Select calendar",
  calendar_watch_title: "Import events from calendars",
  google_calendar_watch_info:
    "Select Google Calendars from which we should import events. We will create blackout dates in the Calendesk calendar to disable your availability.",
  select_calendars: "Select calendars",
  contact_sales: "Contact sales",
  notification_contains_special_characters:
    "This notification contains special characters",
  replace_invalid_sms_characters_checkbox:
    "Remove special characters in SMS notifications (it can reduce the number of SMSes sent)",
  sms_number_of_messages_info:
    'The final number of sent SMS messages may differ after calculating the length of the variables. Enabling the option "Remove special characters in SMS notifications" in the Settings/Platform tab may reduce the number of SMSes sent.',
  blackout_date_all_employees_warning: "Block the calendar for all employees.",
  no_limit: "No limit",
  no_available_time_slots:
    "No available hours. Try changing the date or choosing another service.",
  no_available_time_slots_v2:
    "No available time slots on the selected day. Try changing the date, location, or selecting a different service.",
  select_availability_service_info:
    "Please select a time slot to copy it to the booking form.",
  select_service_error:
    "Please select a service to search for available time slots.",
  booking_awaiting_payment_warning:
    "This booking will be deleted without payment. Time to make a payment:",
  booking_reminder_setting_info: "Time before a booking",
  integrations_google_calendar_id_help:
    'See the article "How to choose different Google calendars?"',
  booking_date_in_the_past_warning:
    "You are trying to edit an event that has already passed. Email/SMS/push notifications and synchronization with external calendars will not be available.",
  update_draft_name_title: "Rename template name",
  draft_name: "Template name",
  wb_duplicate_section_title: "Copy the selected section to a subpage",
  wb_duplicate_section_info:
    "Select a subpage to which you want to copy the section.",
  wb_duplicate_section_success_message: "We successfully copied the section.",
  booking_customer_link: "URL to a customer's booking",
  autocomplete_hint: "Start typing to search",
  redirect_to_external_url: "Redirect to an external URL",
  url_address: "URL address",
  url_example: "e.g. https://your-page.com/",
  open_in_new_tab: "Open in a new tab",
  push_all_future_events_to_calendar_checkbox:
    "Push now all the future bookings and blackout dates that you have planned in Calendesk.",
  google_calendar_id: "Google Calendar ID",
  set_google_calendar_id: "Set Google Calendar ID",
  google_calendar_id_info:
    "If you have multiple Google calendars in one account, you can set a Google Calendar Id for the calendar you want to use. This setting is optional. If you do not enter anything, we will use the primary calendar.",
  google_calendar_example:
    "e.g., c_8gdd84889nd9nxod4@group.calendar.google.com",
  additional_options: "Additional options",
  calendar_view: "Calendar",
  require_billing_data_checkbox: "Require billing address",
  only_company_invoice_data_type_checkbox:
    "Require full data for invoices (TIN, company name, address)",
  you_can_edit_admin_password_info: "You can not update the Admin password.",
  can_not_find_booking:
    "The booking could not be loaded. It has probably been deleted.",
  can_not_find_unavailable_booking_slot:
    "The blackout date could not be loaded, it has probably been deleted.",
  price_additional_info:
    "The minimum amount to process online payments is $0.50 US or equivalent in charge currency.",
  service_types_additional_info:
    "For each option, you can specify a different duration and price.",
  service_settings: "Service settings",
  service_employee_title: "Who will perform the service?",
  service_additional_break_info:
    "Extra time for a break before or after a booking",
  service_max_people_info: "Enable group bookings",
  stripe_payments_warning:
    "Stripe integration is inactive. Connect Stripe to accept payments.",
  advance_settings_label: "Show advanced settings",
  service_availability_employees_info: "Employees use their own schedules.",
  service_availability_service_info:
    "The service has one availability schedule for all employees.",
  service_availability_employees_schedule_title: "Employee schedule",
  service_availability_service_schedule_title: "Service schedule",
  service_free_option: "Free",
  service_max_people_short_info: "Max group",
  required_capability_info:
    "To protect our customers, we need to verify your account to unlock this feature. It's a standard procedure. We got the information, and we are already working on it. So please try again later or contact with us.",
  required_permission_info:
    "Your permissions are insufficient to perform this operation. Please get in touch with your administrator.",
  old_employee_schedule_label: "Use the old version of work schedules",
  old_employee_schedule_label_tooltip:
    "Start using a new version of work schedules: more flexibility for setting days/dates and times.",
  employee_schedule_hint: "e.g., John Doe - Work Hours",
  duplicate_service: "Duplicate a service",
  show: "Show",
  wb_custom_code: "Custom scripts",
  custom_code_info: "Custom scripts will only appear on the published site.",
  wb_show_service_column: "Show the column with service details",
  wb_require_billing_data: "Require billing address",
  private_notes: "Private notes",
  signup_onboarding_title_1: "Welcome to Calendesk!",
  signup_onboarding_subtitle_1:
    "A 5-minute investment now can save you tens of hours later. We will walk you through the most important Calendesk settings in a few simple steps.",
  signup_onboarding_title_2: "Employees",
  signup_onboarding_subtitle_2:
    "Each employee has access to a Calendesk admin panel and can accept online bookings for any service. In addition, each employee has their own availability schedule.",
  signup_onboarding_title_3: "Services",
  signup_onboarding_subtitle_3:
    "Booking calendars will display services for customers. Please add at least one of your services to the calendar now. You can add more services later, organize them into categories, and edit their details.",
  signup_onboarding_title_4: "Calendar availability",
  signup_onboarding_subtitle_4:
    "Availability schedules show when you or your team members are available for appointments and when customers can sign up for them. They can be assigned to employees or services.",
  signup_onboarding_title_5: "Integrations",
  signup_onboarding_subtitle_5:
    "Calendesk can be integrated with external tools to improve your workflow and increase efficiency.",
  signup_onboarding_title_6: "Congratulations!",
  signup_onboarding_subtitle_6:
    "You are ready to accept bookings from your customers.",
  signup_onboarding_card_title_1: "About you",
  signup_onboarding_card_subtitle_1:
    "Add more info about you and your business.",
  signup_onboarding_card_title_2: "Who will use Calendesk?",
  signup_onboarding_card_subtitle_2: "Add basic information about your team.",
  signup_onboarding_card_title_3: "What will you offer to your customers?",
  signup_onboarding_card_subtitle_3:
    "Provide a list of the services available to your customers. Establish the time, price, and who will be serving your customers.",
  signup_onboarding_card_title_4: "Default availability schedule",
  signup_onboarding_card_subtitle_4:
    "Your employees will be assigned a default availability schedule. Later on, you can add more details.",
  signup_onboarding_card_title_5: "Available integrations",
  signup_onboarding_card_subtitle_5:
    "Below is a quick preview of the available integrations. You can easily connect them later in the Calendesk admin panel.",
  signup_onboarding_card_title_6: "Your calendar is now online",
  signup_onboarding_card_subtitle_6:
    "We have created a basic booking calendar for you. With Calendesk, you can easily customize the URL, colors, sections, subpages, and calendar style.",
  signup_onboarding_services_employees_label: "Who will serve your customers?",
  signup_onboarding_domain_label:
    "You can change the URL address in the website builder later.",
  signup_onboarding_employee_is_public_info:
    "An employee's account can accept bookings from customers, but your employees can also use it only to log in, e.g., to view the booking calendar. You can easily change this option later.",
  online_bookings: "Online bookings",
  integrations_category_calendars: "Calendars",
  integrations_category_video: "Video conferencing",
  integrations_category_payments: "Payments",
  integrations_category_accounting: "Invoicing",
  integrations_category_automation: "Automation",
  integrations_category_analytics: "Analytics",
  integrations_category_www: "Website",
  coming_soon_info:
    "Option available soon. We will inform you as soon as we have finished working on it.",
  go_to_services_button: "Go to services",
  start_integration_label: "Start integration",
  integrations_for_employee_tab: "Employee integrations",
  integrations_for_global_tab: "Account integrations",
  zapier: "Zapier",
  zapier_description:
    "With Zapier, you can automate monotonous tasks between two or more apps - without writing a line of code.",
  zapier_description_short:
    "Choose from more than 4,000 integrations via Zapier.",
  google_tag_manager: "Google Tag Manager",
  google_tag_manager_description:
    "Manage all of your website tags in one place. Google Tag Manager is a free system that allows you to manage and deploy marketing tags (snippets of code or tracking pixels) on your website or calendar without modifying code.",
  google_tag_manager_description_short:
    "Manage all of your website tags in one place.",
  calendesk_widget: "Calendesk Widget",
  calendesk_widget_description:
    "Use the Calendesk booking system on any website. Determine if you want to use your calendar as a permanent element, a floating button, or a popup from any website element? Go to the Calendesk website builder and generate your custom code. Place it on your website and let customers make an appointment directly on your website.",
  calendesk_widget_description_short:
    "Use the Calendesk booking system on any website.",
  google_analytics: "Google Analytics",
  google_analytics_description:
    "Google Analytics gives you the free tools you need to analyze data for your business in one place, so you can make smarter decisions.",
  microsoft_teams: "Teams",
  microsoft_skype: "Skype",
  microsoft_outlook: "Outlook",
  work_schedule: "Work schedule",
  category_default_name: "Consultations",
  root_email_info: "You can change the primary email later.",
  email_must_be_unique: "You can not use the same email for multiple accounts.",
  value_must_be_unique: "The value cannot be repeated.",
  billing_data: "Billing details",
  global_error_title: "Sorry, there was a problem while loading Calendesk.",
  global_error_subtitle: "This problem usually occurs for two reasons:",
  global_error_description_1:
    "1. <b>Internet connection</b>. Try to check your internet connection and reload the page.",
  global_error_description_2:
    "2. <b>We are updating Calendesk</b>. Sometimes we need to update the system to add new features or fix some bugs. Please try reloading the page in a few minutes.",
  main_page_button_title: "Home page",
  reload: "Reload",
  wb_show_service_description: "Show service description",
  wb_hide_booking_time_to: "Hide booking end time",
  wb_redirect_logo: "Logo redirect",
  collapse_all: "Collapse all",
  expand_all: "Expand all",
  service_status: "Service status",
  availability: "Availability schedules",
  add_availability_schedule: "Add an availability schedule",
  availability_list_info:
    "Use availability schedules to display booking time slots for your customers. Schedules can be assigned to employees, services, or both. You can share any availability schedules between employees or services. All employees can access and edit/delete work schedules if they are assigned to them or created by them.",
  assigned_to_employees: "Assigned to employees",
  assigned_to_services: "Assigned to services",
  assign_to_employees: "Assign to employees",
  assign_to_services: "Assign to services",
  activate_work_schedule: "Assign to myself",
  section_admin_availability_list: "Panel / Availability schedules",
  test_availability: "Test availability",
  employee_assigned_to_services_title: "Services performed by this employee",
  employee_assigned_to_services_info:
    "Booking services require assigning an employee who will perform them. Select the services that should be set for this employee.",
  wb_column_width: "The width of the column in percentage, e.g. 50",
  max_booking_time: "Booking of the service no sooner than",
  max_user_bookings: "The maximum number of bookings for one customer",
  max_user_bookings_checkbox: "Limit the number of bookings for customers",
  create_user_subscription_button_title: "Assign subscription",
  create_user_subscription_info_1:
    "You can create any type of subscription without involving customers in purchasing them. Recurring subscriptions with booking limits will renew their limits according to their settings. These subscriptions will remain active until you cancel them manually. In the case of one-time fee subscriptions (packages), they will be active until the customer uses their resources or they are manually canceled. The system does not monitor payments to manual subscriptions/packages and does not cancel such subscriptions in the case of non-payment. You can manage payments for manual subscriptions in the Clients/Subscriptions tab. You can also create your first subscription payment below.",
  create_user_subscription_info_2:
    "Select the subscription you want to assign to a customer. Also, you can specify the creation (sale) and end dates of the subscription. The creation date has to be a date in the past. Optionally add payment information if you processed it outside of Calendesk.",
  create_subscription_payment: "I want to create a payment",
  create_payment: "Create payment",
  user_subscription_id: "Customer subscription ID",
  booking_id: "Booking ID",
  create_payment_for_user_subscription:
    "Create a payment for a customer subscription",
  create_payment_invoice_info:
    "The VAT invoice will be automatically issued if you have correctly configured integration with the invoicing system and selected the option to issue VAT invoices upon receiving payment.",
  notification_usage_not_available_info:
    'You do not have enough notifications to send messages. Go to the "Notifications" tab to top up your account.',
  buy_notifications: "Buy notifications",
  notifications_sent: "Your message was sent.",
  employee_names: "Employee's name and surname",
  pay_with_subscription_error_message:
    "You cannot pay with the selected subscription - its limits are exhausted, it is inactive or its creation date is after the reservation date.",
  subscription_created_at: "Subscription creation date",
  subscription_ends_at: "Subscription ends date",
  wants_create_subscription_end_date_checkbox:
    "I want to set a subscription end date",
  manage: "Manage",
  status_and_payment: "Status/Payment",
  duplicate_booking: "Duplicate the booking",
  edit_booking: "Edit the booking",
  edit_unavailable_booking_slot: "Edit the blackout date",
  delete_unavailable_booking_slot: "Delete the blackout date",
  disable_indexing_by_robots:
    "Do not allow search engine robots to index the subscription purchase page",
  show_employees_working_hours:
    "Show employee availability schedules in the calendar",
  integrations_category_employees: "Calendars and video conferencing",
  integrations_google_tools_title: "Google Services",
  integrations_microsoft_tools_title: "Microsoft Services",
  outlook_calendar_connect_button_title: "Start integrating with Microsoft",
  google_calendar_connect_button_title: "Start integrating with Google",
  google_calendar_description:
    "Access your bookings in your Google Calendar. Receive information about your new bookings or updates directly into your personal calendar by connecting Calendesk to your Google account. Allow Calendesk to check your calendar events to keep track of your availability.",
  outlook_calendar_description:
    "Access your bookings in your Outlook Calendar. Receive information about your new bookings or updates directly into your personal calendar by connecting Calendesk to your Microsoft account. Allow Calendesk to check your calendar events to keep track of your availability.",
  microsoft_teams_description:
    "Set your service locations to Teams and let Calendesk create a unique Teams link for your bookings. The link will be sent to you and your client automatically.",
  microsoft_skype_description:
    "Set your service locations to Skype and let Calendesk create a unique Skype link for your bookings. The link will be sent to you and your client automatically.",
  whatsapp: "WhatsApp",
  whatsapp_description:
    "Link your WhatsApp number to Calendesk. Thanks to the integration of WhatsApp with Calendesk and the appropriate location setting for your service, your confirmed bookings will contain a WhatsApp meeting link for you and your client.",
  whatsapp_description_short:
    "With WhatsApp, you'll get fast, simple, secure messaging and calling on phones all over the world.",
  whatsapp_connect_button_title: "Connect your WhatsApp",
  calendar_public_label: "Show private event descriptions after importing them",
  calendar_public_tooltip:
    "Calendesk can display titles of private events from your connected calendars. The descriptions will be visible to all your colleagues who have access to your bookings and blackout dates. Changing this option only affects future imported events.",
  outlook_calendar_add_title:
    "Send bookings and blackout dates to Outlook Calendar",
  outlook_calendar_add_info:
    "Select Outlook Calendar to which we should send new bookings and blackout dates from Calendesk.",
  outlook_calendar_watch_info:
    "Select Outlook Calendars from which we should import events. We will create blackout dates in the Calendesk calendar to disable your availability.",
  outlook_calendar_auth_success_title: "Microsoft authorization successful",
  outlook_calendar_disconnect_dialog_title: "Disconnect Outlook Calendar",
  outlook_calendar_disconnect_dialog_info:
    "Are you sure you want to disconnect your Outlook Calendar? We will no longer send your future Calendesk bookings and blackout dates to your Outlook Calendar.",
  outlook_calendar_disconnect_success_message:
    "The calendar has been properly disconnected.",
  microsoft_supported_tools:
    "Supported integrations for your Microsoft account",
  whatsapp_add_title: "Your WhatsApp number",
  whatsapp_add_info:
    "Enter the WhatsApp number you want to use to communicate with your customers. This number will only be assigned to your Calendesk account.",
  whatsapp_disconnect_dialog_title: "Disconnect WhatsApp",
  whatsapp_calendar_disconnect_dialog_info:
    "Are you sure you want to disconnect the WhatsApp integration? Your future Calendesk bookings will no longer be linked to this WhatsApp number.",
  whatsapp_calendar_disconnect_success_message:
    "The WhatsApp integration has been properly disconnected.",
  recurrence_event: "Recurring",
  google_services_description_short:
    "Access to bookings in your Google Calendar. Use Google Meet for video calls with clients.",
  microsoft_services_description_short:
    "Send bookings and blackout dates to your Outlook Calendar. Connect with clients using Microsoft Teams or Skype.",
  notifications_running_out_of_notifications:
    "Your e-mail/SMS notifications are running low. Top up to keep your customers informed about bookings.",
  choose_email_notifications_package: "Select an email notification package",
  choose_sms_notifications_package: "Select an SMS notification package",
  notifications_packages_info:
    "Notification packages do not expire. You can use them as long as you use Calendesk. Package notifications are used when you run out of the ones you receive each month under your plan.",
  never_expires: "Valid forever",
  email_package: "Email package",
  sms_package: "SMS package",
  email_packages: "Email packages",
  sms_packages: "SMS packages",
  extra_notification: "extra notifications",
  go_to_notifications: "Go to notifications",
  days: "Days",
  hours: "Hours",
  hour: "Hour",
  minutes_full: "Minutes",
  seconds: "Seconds",
  black_friday_title: "Black Friday 2022!",
  black_friday_subtitle: "Don't forget about your customers!",
  black_friday_ends_info: "Time left on sale",
  black_friday_info_1:
    "We have prepared a special promotion for e-mail and SMS notifications! Receive extra notifications when purchasing e-mail or SMS packages.",
  black_friday_info_2:
    "Use the possibility of sending e-mail and SMS notifications to your customers and prepare something extra for them!",
  show_canceled_bookings: "Show canceled bookings in the calendar",
  upselling_title: "Upsells",
  upselling_info:
    "Upselling is a powerful tool for businesses to increase revenue and improve customer satisfaction. By offering additional products or services during the booking process, you can give your customers the opportunity to enhance their experience and potentially spend more money. Simply choose the products that you want to offer when they make a booking for a service. This is a straightforward way to boost sales and delight your customers.",
  upselling_info_2:
    'The "Products" feature (upselling) can bring many benefits to your business during the booking process. By offering additional products or services, you can increase both revenue and customer satisfaction. You can assign selected products to specific services by editing them in the "Offer/Services" tab.\t',
  simple_store_products: "Products",
  add_simple_store_products_with_plus:
    'No products. Add products by clicking "+" on the right.',
  product_has_transactions_delete_error:
    "You can't delete a product that has already been sold and has payment transactions.",
  additional_information: "Additional information",
  simple_product_name_example: "Example product name",
  simple_product_description_example:
    "You will pick up the product during your visit.",
  simple_store_product_description_title:
    "Additional product information after purchase",
  simple_store_product_description_info:
    "Additional information that will be provided after purchasing the product in an email notification.",
  pay_for_products: "Pay for the products",
  add_transaction: "Add transaction",
  additional_products: "Additional products",
  christmas_wishes_title: "Merry Christmas 🎅🌟",
  christmas_wishes_subtitle_1:
    "We wish you not only joy and happiness during the Christmas and New Year holidays, but also all the best and the fulfillment of your dreams. We hope you spend this time with your loved ones and enjoy every moment. May the New Year bring you much joy and satisfaction with your achievements.",
  christmas_wishes_subtitle_2: "Calendesk team 🤗",
  user_requested_employee_title: "The client has selected an employee.",
  subscription_renews_info: "Your subscription will renew",
  product_active: "Product is active",
  simple_store_product_additional_information_title:
    "Additional product information before purchase",
  simple_store_product_additional_information_description:
    "Additional information will be displayed during the purchase of the product when the product is purchased independently (outside the booking system).",
  upselling_description_title: "Additional text",
  upselling_description_subtitle:
    "Additional text that we will display when booking the service. You can use it, for example, to promote additional products.",
  wb_init_calendar_title_example:
    "Select a service to go to the booking calendar 👇",
  wb_init_calendar_title: "Title when selecting a service",
  wb_initial_service_selection: "Show initial services selection page",
  add_to_google_calendar_button: "Add to Google Calendar",
  add_to_google_ical_outlook_button: "Add to iCal/Outlook",
  add_to_calendar_variable_title: '"Add to calendar" buttons',
  manage_booking_button_title: "Manage your booking",
  manage_bookings_button_title: "Go to bookings",
  manage_booking_button_variable_title: '"Manage your booking" button',
  manage_bookings_button_variable_title: '"Go to bookings" button',
  wb_language_not_supported_info:
    "Unfortunately, the language you've picked isn't totally supported by Calendesk website builder. But don't worry, your customers will still be able to see translated bits and bobs that you can't change.",
  group_booking_title: "Group booking",
  edit_group_booking_title: "Editing a group booking",
  edit_single_instance_group_booking_title:
    "Editing a single booking from a group",
  group_booking_description:
    "One employee and many customers during the meeting",
  edit_booking_title: "Editing a booking",
  single_booking_description:
    "One employee and one customer during the meeting",
  blackout_date_title: "Blackout date",
  edit_unavailable_booking_slot_title: "Editing a blackout date",
  blackout_date_description: "Block the ability for clients to make bookings",
  select_service_from_list_title: "Select a service",
  select_services_title: "Select services",
  availability_window_button_title: "See employee availability",
  booking_form_service_question:
    "Which service do you want to book a meeting for?",
  booking_form_employee_question: "Who should conduct the meeting?",
  booking_form_user_question: "Who will the meeting be with?",
  booking_form_date_question: "When is the meeting to take place?",
  booking_location_question: "Where is the meeting to take place?",
  booking_form_additional_question: "Additional settings",
  blackout_date_form_date_question: "When do you want to block the calendar?",
  blackout_date_form_employee_question: "Whose calendar do you want to block?",
  available_hours_title:
    "The nearest available dates of the selected day for employees (@{date}):",
  update_recurring_event_title: "Editing a recurring event",
  update_recurring_event_question:
    "This event is a recurring event. What do you want to edit?",
  event_updated_no_notifications_label:
    "Do not inform about the change of visit",
  type: "Type",
  event_type: "Event type",
  event_type_booking: "Booking",
  event_type_group_booking: "Group booking",
  event_type_unavailable_booking_slot: "Blackout date",
  calendesk: "Calendesk",
  blackout_date_default_value: "Calendesk Blackout Date",
  booking_recurrence_description:
    "every third day, starting from 10.06.2023, 10 times",
  manage_booking_event: "Booking",
  manage_unavailable_booking_slot_event: "Blackout date",
  wants_set_end_time_label: "I want to set the end of the meeting manually",
  wants_set_end_time_info:
    "Choose the option to manually set the end time of the meeting instead of the standard service length.",
  new_booking_title: "New booking",
  new_unavailable_booking_slot_title: "New blackout date",
  show_more_employees: "Show more (@{number})",
  select_employee_label: "Select employee",
  select_employees_label: "Select employees",
  select_customer_label: "Select customer",
  select_customers_label: "Select customers",
  booking_from_group: "Booking from a group\t",
  bookings_in_group: "Booking in a group",
  event_preview_type_booking: "Preview of the booking",
  event_preview_type_unavailable_booking_slot: "Preview of the blackout date",
  event_preview_type_group_booking: "Preview of the group booking",
  event_history_title: "History of changes",
  events: "Events",
  download_bookings_csv_title: "Download CSV (Bookings)",
  download_unavailable_booking_slots_csv_title: "Download CSV (Blackout dates)",
  employee_is_public_filter_title: "Bookings from customers",
  employee_is_public_filter_visible: "Accepts bookings",
  employee_is_public_filter_hidden: "Doesn't accept bookings",
  wb_calendar_min_date: "Display availability from the day",
  wb_calendar_max_date: "Display availability until the day",
  delete_user_subscriptions: "Remove customer subscriptions",
  cancel_user_subscriptions: "Cancel customer subscriptions",
  payment_date: "Payment date",
  active_subscriptions: "Active subscriptions",
  inactive_subscriptions: "Inactive subscriptions",
  newsletter_new_record_info:
    "We will send a message to the provided email address, through which the user will be able to confirm it.",
  google_calendar_permissions_error:
    "Calendesk does not have full access to your calendar list. It is possible that you deselected the required permissions during integration with Google Calendar. Try disconnecting and reconnecting the integration. If you do not want to share your calendar list with us, manually enter the unique calendar ID number.",
  description_too_long: "The text you entered is too long",
  identity_number_label: "Internal number",
  sales: "Sales",
  offer: "Offer",
  tools: "Tools",
  activity: "Activity",
  billing: "Billing",
  notification_activity_info:
    "Here you will find the history of emails, SMS, or push notifications sent to your customers and employees.",
  root_account_title: "Main account",
  blackout_date_move_to_another_resource_error:
    "You cannot change the blackout date by assigning it to another employee. Try to remove and add it again.",
  cancel_group_bookings_title: "Cancel the entire group",
  delete_group_bookings_title: "Delete the entire group",
  duplicate_group_bookings_title: "Duplicate the entire group",
  logs_title: "Activity logs",
  logs_type_title: "Change type",
  logs_diff_before_title: "Change before",
  logs_diff_after_title: "Change after",
  logs_diff_name: "Name",
  logs_diff_surname: "Surname",
  logs_diff_email: "Email",
  logs_diff_status: "Status",
  logs_diff_date_of_birth: "Date of birth",
  logs_diff_gender: "Gender",
  logs_diff_identity_number: "Internal number",
  logs_diff_firebase_token: "Internal token (PUSH)",
  logs_diff_draft_uuid: "Draft ID",
  logs_diff_stripe_id: "Stripe ID",
  logs_diff_fakturownia_id: "InvoiceOcean ID",
  logs_diff_pm_type: "Card type",
  logs_diff_pm_last_four: "The last four digits of the card",
  logs_diff_payment_method_id: "Payment method ID",
  logs_diff_gdpr_fields: "GDPR fields",
  logs_diff_email_verified_at: "Email verification",
  logs_diff_date_of_names_day: "Name day date",
  logs_diff_card_number: "Card number",
  logs_diff_description: "Description",
  logs_diff_start_date: "Start date",
  logs_diff_end_date: "End date",
  logs_diff_start_time: "Start time",
  logs_diff_end_time: "End time",
  logs_diff_control: "Control number",
  logs_diff_custom_fields: "Custom fields",
  logs_diff_google_calendar_event_id: "Google Calendar Event ID",
  logs_diff_google_meet_url: "Google Meet URL",
  logs_diff_paid: "Payment",
  logs_diff_skip_create_notifications: "Skip creation notifications",
  logs_diff_skip_update_notifications: "Skip update notifications",
  logs_diff_skip_delete_notifications: "Skip deletion notifications",
  logs_diff_group_id: "Group ID",
  logs_diff_service_location_id: "Location ID",
  logs_diff_paid_with_user_subscription_id: "Paid with user's subscription ID",
  logs_diff_recurrence_params: "Recurrence parameters",
  logs_diff_payment_method: "Payment method",
  logs_diff_service_id: "Service ID",
  logs_diff_service_type_id: "Service option ID",
  logs_diff_employee_id: "Employee ID",
  logs_diff_user_id: "User ID",
  logs_diff_multi_slot_group_id: "Group booking ID",
  logs_diff_zoom_meeting_id: "Zoom meeting ID",
  logs_diff_zoom_start_url: "Zoom start URL",
  logs_diff_zoom_join_url: "Zoom join URL",
  logs_diff_created_by: "Created by",
  logs_diff_updated_by: "Updated by",
  logs_diff_customer_time_zone: "Customer's time zone",
  logs_diff_zoom_occurrence_id: "Zoom single occurrence ID",
  logs_diff_customer_time_notation: "Customer time notation",
  logs_diff_outlook_calendar_event_id: "Outlook Calendar Event ID",
  logs_diff_booking_payment_transaction_id:
    "Payment transaction ID for the booking",
  logs_diff_teams_url: "Teams URL",
  logs_diff_skype_url: "Skype URL",
  logs_diff_employee_whatsapp_url: "Employee's WhatsApp URL",
  logs_diff_customer_whatsapp_url: "Customer's WhatsApp URL",
  logs_diff_image_id: "Image ID",
  logs_diff_data: "Data",
  logs_diff_success_url: "Success URL",
  logs_diff_recurring_interval: "Recurring interval",
  logs_diff_service_limit: "Service limit",
  logs_diff_tax: "Tax",
  logs_diff_price: "Price",
  logs_diff_quantity: "Quantity",
  logs_diff_additional_information: "Additional information",
  logs_diff_rules: "Rules",
  logs_diff_canceled_at: "Cancel date",
  logs_diff_wants_invoice: "Invoice",
  logs_diff_cancel_url: "Cancellation/error URL",
  logs_diff_require_billing_data: "Require billing address",
  logs_diff_disable_robot_indexing:
    "Don't allow search engine robots to index this subscription",
  logs_diff_only_company_invoice_data_type:
    "Require full data for invoices (TIN, company name, address)",
  logs_diff_stripe_subscription_id: "Stripe Subscription ID",
  logs_type_created: "Created",
  logs_type_updated: "Updated",
  logs_type_deleted: "Deleted",
  logs_subject_type: "Object type",
  logs_subject_type_user: "User",
  logs_subject_type_booking: "Booking",
  logs_subject_type_subscription: "Subscription",
  logs_subject_type_employee: "Employee",
  logs_subject_type_unavailablebookingslot: "Blackout date",
  logs_subject_type_service: "Service",
  logs_subject_type_servicetype: "Option",
  logs_subject_type_servicelocation: "Linking location to service",
  logs_subject_type_serviceemployee: "Linking employee to service",
  logs_subject_type_servicesimplestoreproduct: "Linking product to service",
  logs_subject_type_serviceresource: "Linking resource to service",
  logs_subject_type_bookingtag: "Linking tag to booking",
  logs_subject_type_servicesubscription: "Linking subscription to service",
  logs_subject_type_bookingsimplestoreproduct: "Linking product to booking",
  logs_subject_type_usergroup: "Linking group to user",
  logs_subject_type_category: "Category",
  logs_subject_type_userpreferences: "User preferences",
  logs_subject_type_employeeworkinghour: "Employee's working hours",
  logs_subject_type_group: "Group",
  logs_subject_type_permission: "Permission",
  logs_subject_type_role: "Role",
  logs_subject_type_subscriptionpaymenttransaction: "Subscription payment",
  logs_subject_type_simplestoreproducttransaction: "Product payment",
  logs_subject_type_bookingpaymenttransaction: "Booking payment",
  logs_subject_type_usersubscription: "User subscription",
  logs_subject_type_apikey: "API key",
  logs_subject_type_availabilityschedule: "Availability schedule",
  logs_subject_type_tag: "Label",
  logs_subject_type_employeewatchedgooglecalendar: "Google Calendar",
  logs_subject_type_employeewatchedoutlookcalendar: "Outlook calendar",
  logs_subject_type_resource: "Resource",
  logs_subject_type_product: "Product",
  logs_subject_type_settings: "Settings",
  logs_subject_type_simplestoreproduct: "Product",
  logs_subject_type_address: "Address",
  logs_subject_type_phone: "Phone",
  logs_subject_type_templatesettings: "Templates settings",
  logs_subject_type_location: "Location",
  logs_changer_name: "Changer",
  employee_not_available_in_this_service_error:
    "The employee is not assigned to this service.",
  logs_diff_booking_id: "Booking ID",
  logs_diff_transaction_id: "Transaction number / comment",
  logs_diff_provider_tag: "Method",
  logs_diff_paid_at: "Paid",
  logs_diff_promo_code: "Discount code",
  logs_diff_currency: "Currency",
  logs_diff_email_notification_booking_created: "Booking confirmation - email",
  logs_diff_email_notification_booking_updated: "Booking change - email",
  logs_diff_email_notification_booking_deleted: "Booking cancellation - email",
  logs_diff_email_notification_booking_reminder: "Booking reminder - email",
  logs_diff_sms_notification_booking_created: "Booking confirmation - SMS",
  logs_diff_sms_notification_booking_updated: "Booking change - SMS",
  logs_diff_sms_notification_booking_deleted: "Booking cancellation - SMS",
  logs_diff_sms_notification_booking_reminder: "Booking reminder - SMS",
  logs_diff_model_id: "Related with ID",
  logs_diff_e164: "Phone",
  logs_diff_street: "Street",
  logs_diff_postal_code: "Postal code",
  logs_diff_city: "City",
  logs_diff_country_iso_code: "Country",
  logs_diff_vat_iso_prefix: "Tax prefix",
  logs_diff_tax_number: "Tax ID number",
  logs_diff_category_id: "Category ID",
  logs_diff_duration: "Duration",
  logs_diff_booking_time_before: "Booking of the service no later than",
  logs_diff_cancel_time_before: "Cancellation of the service no later than",
  logs_diff_change_time_before: "Rescheduling of the service no later than",
  logs_diff_max_people: "Capacity",
  logs_diff_slots_every: "Show customers the option to book every",
  logs_diff_allow_online_payment: "Enable online payments for this service",
  logs_diff_awaiting_booking_payment_time: "Maximum time to pay for a booking",
  logs_diff_payment_required: "Prepayment",
  logs_diff_order_position: "Order position",
  logs_diff_max_booking_time: "Booking of the service no sooner than",
  logs_diff_upselling_description: "Additional text",
  logs_diff_type: "Type",
  logs_diff_interval_count: "Repeat every",
  logs_diff_color: "Color",
  logs_diff_is_public: "Accepts bookings",
  logs_diff_title: "Title",
  logs_diff_facebook: "Link to a Facebook profile",
  logs_diff_instagram: "Link to an Instagram profile",
  logs_diff_youtube: "Link to a YouTube profile",
  logs_diff_twitter: "Link to a Twitter profile",
  logs_diff_availability_schedule_id: "Availability schedule ID",
  logs_diff_fakturownia_invoice_id: "InvoiceOcean ID",
  logs_diff_invoice_number: "Invoice number",
  logs_diff_user_subscription_id: "Customer subscription ID",
  logs_diff_uuid: "ID",
  logs_diff_id: "ID",
  logs_diff_tag_id: "Tag ID",
  logs_diff_timezone: "Time zone",
  logs_diff_google_calendar_enabled: "Google calendar enabled",
  logs_diff_zoom_user_email: "Zoom user email",
  logs_diff_google_calendar_id: "Google Calendar ID",
  logs_diff_teams_business_enabled: "Teams for business enabled",
  logs_diff_skype_business_enabled: "Skype for business enabled",
  logs_diff_skype_consumer_enabled: "Skype for consumers enabled",
  logs_diff_outlook_enabled: "Outlook enabled",
  logs_diff_outlook_calendar_id: "Outlook calendar ID",
  logs_diff_is_google_calendar_public:
    "Google calendar descriptions are visible",
  logs_diff_is_outlook_public: "Outlook calendar descriptions are visible",
  logs_diff_whatsapp_number: "WhatsApp number",
  logs_diff_break_time_before: "Break time before",
  logs_diff_break_time_after: "Break time after",
  logs_diff_only_subscriptions: "Subscriptions only",
  logs_diff_max_user_bookings: "Maximum user bookings",
  logs_diff_simple_store_product_id: "Product ID",
  logs_diff_resource_id: "Resource ID",
  logs_diff_subject: "Title",
  logs_diff_content: "Content",
  logs_diff_type_id: "Type ID",
  subject_id: "ID Number",
  logs_diff_subscription_id: "Subscription ID",
  logs_subject_type_notificationtemplate: "Notification templates",
  no_integration_employee_warning:
    "The integrations of the selected employee do not allow a meeting to be held at the chosen location. The meeting link will not be generated. Change the location or choose another employee.",
  single_booking_in_group_edit_warning:
    "You are editing a single booking assigned to a group with recurring meetings set up. You cannot change the service, time, and employee in this case. If the booking is outdated, cancel it and add it again with the appropriate settings. If you want to change settings for the entire group, go to group editing.",
  group_booking_update_unavailable_in_calendar_view:
    "Editing a group booking by dragging and dropping is not possible. To make edits, click on the booking.",
  event_deleting_title: "Deleting events",
  event_deleting_question:
    "Are you sure you want to delete the selected event/events?",
  do_not_group_booking_checkbox_label: "Do not group bookings",
  employee_limit_dialog_info:
    "Your current plan does not allow for adding a new employee. To create a new account, please contact us via chat or email at",
  tenant_account_frozen_error:
    "Your account has been inactive for a long time. We are restoring your data. Please try again in a few minutes.",
  client_website_bookings_tab: "Bookings Tab",
  client_website_user_subscriptions_tab: "Subscriptions Tab",
  client_website_payments_tab: "Payments Tab",
  client_website_my_account_tab: "My account Tab",
  invoice_warning_client_data:
    "The VAT invoice will not be issued due to the lack of complete customer billing details.",
  invoice_warning_fakturownia_disabled:
    "The VAT invoice will not be issued due to the disabled integration with the InvoiceOcean system.",
  default_price: "$100.00",
  added_location_notification: "Location added",
  updated_location_notification: "Location updated",
  add_location_title: "Add a location",
  update_location_title: "Edit location",
  add_locations_with_plus:
    'No locations found. Add a location by clicking "+" on the right side.',
  add_availability_schedule_conditions_title: "Add conditions",
  edit_availability_schedule_conditions_title: "Change conditions",
  add_availability_schedule_exception_title: "Add exception",
  edit_availability_schedule_exception_title: "Change exception",
  availability_schedule_conditions_description:
    "Conditions allow assigning services or locations to specific time ranges. This means that when a customer browses the booking calendar, the system automatically checks availability with consideration of additional criteria such as selected services or locations.",
  timezone: "Time zone",
  copy_hours_button_title: "Copy hours",
  copy_hours_from_monday_item: "Copy hours from Monday",
  copy_hours_from_tuesday_item: "Copy hours from Tuesday",
  copy_hours_from_wednesday_item: "Copy hours from Wednesday",
  copy_hours_from_thursday_item: "Copy hours from Thursday",
  copy_hours_from_friday_item: "Copy hours from Friday",
  copy_hours_from_saturday_item: "Copy hours from Saturday",
  copy_hours_from_sunday_item: "Copy hours from Sunday",
  add_hours_range_button_tooltip:
    "Add a new hours range that you can assign to specific locations or services.",
  add_hours_interval_button_tooltip:
    "Create a new time interval within an existing hours range.",
  delete_date_range_warning_title: "Delete date range",
  delete_date_range_warning_description:
    "Are you sure you want to delete this date range? This will disable exceptions for selected days.",
  add_new_date_range_button_tooltip:
    "Add exceptions for weekdays to modify the standard work schedule.",
  exceptions_date_range_title: "Exceptions - dates overriding weekdays",
  weekday_rules_title: "Standard work week",
  exceptions_date_range_info:
    "By adding exception dates, you can set a special schedule for specific days, thereby ignoring standard settings for those days.",
  availability_schedule_assign_to_employees_info:
    "Remember that each employee can have only one availability schedule assigned. Assigning a new availability schedule to the employees listed below will replace their current schedules.",
  availability_schedule_assign_to_services_info:
    "Remember that only one availability schedule can be assigned to each service. Services can utilize the availability schedules assigned to employees or have their own dedicated schedule. Assigning the selected availability schedule to the services listed below will overwrite their current schedule settings. These services will use the selected availability schedule.",
  other_settings_title: "Other settings",
  subscription_status_info:
    "An active subscription allows customers to freely purchase and use it, while an inactive subscription prevents new purchases. Customers who previously purchased a subscription can still use it without changes despite its deactivation, but they will not have the option to purchase a new one.",
  subscription_stop_automatic_payments_checkbox_title:
    "Stop collecting payments and end subscription after a specified time",
  subscription_stop_automatic_payments_after: "End collecting payments after",
  subscription_allow_users_to_cancel_checkbox_title:
    "Allow customers to cancel active subscriptions themselves in the customer panel",
  subscription_prices_warning:
    "Remember, the subscription amount and any termination time cannot be changed after it's created. If you want to modify these parameters, you must create a new subscription.",
  cancel_subscription_dialog_title: "Canceling customer's subscription",
  cancel_single_subscription_dialog_question:
    "Are you sure you want to cancel the selected subscription?",
  cancel_multiple_subscription_dialog_question:
    "Are you sure you want to cancel the selected subscriptions?",
  cancel_subscription_immediately_checkbox_title:
    "Check to cancel immediately, not waiting until the end of the period.",
  cancel_single_subscription_confirm_button: "Cancel subscription",
  cancel_multiple_subscription_confirm_button: "Cancel subscriptions",
  selected_ids_label: "Selected Ids",
  service_location_online: "Online",
  service_location_phone: "Phone",
  service_location_name: "Name",
  wb_select_locations_title_label: "Text when selecting location",
  wb_select_services_title_label: "Text when selecting service",
  wb_select_employees_title_label: "Text when selecting employee",
  wb_select_locations_title_example_1: "Choose a location,",
  wb_select_locations_title_example_2: "to view available services",
  wb_any_employee_enabled:
    "Automatically select a random employee, skipping the selection step",
  wb_show_expand_collapse_button: 'Show "Show more hours" button',
  wb_show_booked_slots: "Show booked appointments in the calendar",
  wb_initial_location_select: "Automatically select the first location",
  show_more_hours_button: "Show more hours",
  add_another_button_title: "Add another",
  wb_dummy_text_employee_description:
    "This is a sample employee description. You can set it in the Calendesk admin panel when editing the employee's account. You can choose any text for different employees.",
  any_person_label: "Any person",
  selected_time_slots_label: "Selected appointments",
  logs_diff_phone_id: "Phone ID",
  logs_diff_address_id: "Address ID",
  user_time_zone_title: "Language and timezone settings",
  user_time_zone_locale_info:
    'Employees can set their own timezone and language preferences. Bookings and the admin panel will be displayed according to their selection. If no preferences are set, the system will automatically apply the default timezone and language for the entire Calendesk account, as specified in the "Settings / Platform" tab.',
  app_employees_time_zone_enabled_title:
    "Allow employees to set their own timezones",
  app_employees_locale_enabled_title:
    "Allow employees to set their own panel language",
  logs_diff_time_zone: "Time zone",
  logs_diff_locale: "Language",
  booking_reminders_headline: "Manage reminder send times",
  booking_reminders_info:
    "The new reminder notification send time setting will only apply to bookings created after the change.",
  booking_reminders_enable_custom_reminder_checkbox:
    "I want to set different send times for different types of reminders",
  booking_reminders_element_receiver_label: "Recipient",
  booking_reminders_headline_2: "Current reminders",
  booking_reminders_recipient_type_1: "Customer",
  booking_reminders_recipient_type_2: "Employee",
  booking_reminders_add_new_button: "Add new reminder",
  booking_reminders_no_reminders_info_label:
    "No reminders set for bookings, click below to add the first reminder. 👇",
  booking_reminders_added_successfully_label:
    "Notifications have been added successfully",
  captcha_invalid_error_notification:
    "Captcha verification incorrect. Try reloading the page if the problem persists.",
  verify_code_incorrect_error: "The entered code is incorrect.",
  verify_code_too_many_requests:
    "We are currently unable to generate a new code. Please try again later or use the code we have sent.",
  send_again_button_title: "Send again",
  verify_title: "Verify",
  verify_code_title: "Enter verification code",
  verify_code_description:
    "We have sent a 6-digit verification code to your email. Please enter it below:",
  general_settings_title: "General settings",
  bookings_settings_title: "Booking settings",
  employees_settings_title: "Employee settings",
  create_booking_email_verification_required_checkbox:
    "Require email verification from non-logged-in customers when creating a booking",
  verification_invalid_error_notification:
    "Invalid verification. Try again later.",
  too_many_requests:
    "Oops! You're moving a bit too fast. Slow down and try again in a moment.",
  notification_category_0: "System notification",
  notification_category_1: "Booking prepayment",
  notification_category_2: "Booking confirmation",
  notification_category_3: "Booking change",
  notification_category_4: "Booking cancellation",
  notification_category_5: "Booking reminder",
  notification_category_6: "Account activation",
  notification_category_7: "Password reset",
  notification_category_8: "Store transaction",
  notification_category_9: "Mobile app links",
  notification_category_10: "Custom notification",
  notification_category_11: "Newsletter subscription",
  notification_category_12: "Verification code",
  notification_category_13: "Contact from the website",
  notification_status_0: "Unknown",
  notification_status_1: "Sent",
  notification_status_2: "Delivered",
  notification_status_3: "Not delivered",
  notification_status_4: "Error occurred",
  notification_status_5: "Link clicked in email",
  notification_status_6: "Email opened",
  errors: "Errors",
  enter_image_url: "Enter image URL",
  enter_url: "Enter URL",
  html_editor_tooltip_bold: "Bold text",
  html_editor_tooltip_italic: "Italic",
  html_editor_tooltip_underline: "Underline",
  html_editor_tooltip_strike: "Strikethrough",
  html_editor_tooltip_paragraph: "Paragraph",
  html_editor_tooltip_heading: "Heading",
  html_editor_tooltip_bullet_list: "Bullet list",
  html_editor_tooltip_code_block: "Code block",
  html_editor_tooltip_horizontal_rule: "Horizontal line",
  html_editor_tooltip_hard_break: "Break",
  html_editor_tooltip_add_mage: "Add image",
  html_editor_tooltip_link: "Add link",
  html_editor_tooltip_unlink: "Remove link",
  html_editor_tooltip_font_size: "Font size",
  html_editor_tooltip_font_color: "Font color",
  html_editor_tooltip_background_color: "Background color",
  html_editor_tooltip_delete_background_color: "Remove background color",
  html_editor_tooltip_set_html: "Insert HTML",
  html_editor_tooltip_undo: "Undo",
  html_editor_tooltip_redo: "Redo",
  html_editor_tooltip_align_left: "Align left",
  html_editor_tooltip_align_right: "Align right",
  html_editor_tooltip_align_center: "Align center",
  html_editor_tooltip_align_justify: "Justify alignment",
  subscription_cancel_reason_info:
    "Help us improve our services to better meet your needs! Share with us the reason for canceling your subscription, and with your feedback, future solutions will be more tailored to you.",
  subscription_cancel_reason_placeholder: "Your feedback",
  subscription_cancel_error:
    "Something went wrong. Check if your subscription is active and if there are no outstanding payments. Contact us for more information.",
  subscription_cancel_data_warning:
    "After the subscription ends, all data from the booking system may be permanently deleted.",
  reply_to_label: "Reply-To",
  change_sender_name_button: "Change Sender Name",
  change_sender_name_info:
    "If you want to change the sender name displayed in your notifications, click the button below and go to the company settings.",
  wb_expand_collapse_service_panels: "Group Services into Categories",
};
